@import url("./assets/css/animate.css");
@import url("./assets/css/all.css");
@import url("./assets/css/bootstrap.min.css");
@import url("./assets/css/boxicons.min.css");
@import url("./assets/css/bootstrap-icons.css");
@import url("./assets/css/swiper-bundle.min.css");
@import url("./assets/css/slick-theme.css");
@import url("./assets/css/slick.css");
@import url("./assets/css/nice-select.css");
@import url("./assets/css/magnific-popup.css");
@import url("./assets/css/odometer.css");
@import url("./assets/style.css");

.card-detail {
	display: flex;
	align-items: center;
	border: 1px solid #e0e0e0;
	padding: 10px;
	border-radius: 5px;
	margin-bottom: 10px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
	transition: box-shadow 0.3s ease;
  }
  
  .card-detail:hover {
	box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  }

  .username-chip-container {
	display: flex; /* Ensure it's a flex container */
	flex-direction: row; /* Arrange children in a row */
	align-items: center; /* Align children vertically in the middle */
  }	

.gallery-img {
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: auto;
  }

@media only screen and (min-width: 1199px) {
  .main-menu ul li:hover ul.submenu {
    display: block !important;
    transition: all linear 0.3s;
  }
}
.select {
  position: relative;
  /* margin-bottom: 15px; */
  width: 80px;
}

.select.style-2 {
  position: relative;
  /* margin-bottom: 15px; */
  width: 100%;
  border: 1px solid #eee;
  height: 50px;
  line-height: 30px;
  border-radius: 5px;
}

.select.style-2 .selectBtn {
  font-size: 13px;
  font-weight: 400;
  font-family: "raleway", sans-serif;
  color: #696969;
}

.select .selectBtn {
  background: var(--bg1);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: "lato", sans-serif;
  color: #696969;
}

.select .selectBtn:after {
  content: "";
  position: absolute;
  top: 45%;
  right: 20px;
  width: 7px;
  height: 7px;
  transform: translateY(-50%) rotate(45deg);
  border-right: 2px solid #696969;
  border-bottom: 2px solid #696969;
  transition: 0.2s ease;
}

.select .selectBtn.toggle {
  border-radius: 3px 3px 0 0;
}

.select .selectBtn.toggle:after {
  transform: translateY(-50%) rotate(-135deg);
}

.select .selectDropdown {
  position: absolute;
  top: 40px;
  width: 100%;
  border-radius: 0 0 3px 3px;
  overflow: hidden;
  background: var(--bg1);
  border-top: 1px solid #eee;
  z-index: 1;
  background: #fff;
  transform: scale(1, 0);
  transform-origin: top center;
  visibility: hidden;
  transition: 0.2s ease;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}

.select .selectDropdown .option {
  padding: 8px 10px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-family: "lato", sans-serif;
  color: #696969;
}

.select .selectDropdown .option:hover {
  background: #5ECC76;
  color: #fff;
}

.select .selectDropdown.toggle {
  visibility: visible;
  transform: scale(1, 1);
}/*# sourceMappingURL=index.css.map */

.gallery-container {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;
  }
  
.custom-image-gallery {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
  }

  /* .main-image:hover img {
	transform: scale(1.2);
  } */

  .main-image {
	position: relative;
	width: 100%; /* Adjust the width as needed */
	max-width: 100%; /* Ensure the image doesn't exceed the screen width */
	overflow: hidden;
	cursor: pointer;
  }

/* Adjust main image height for Small screens (up to 640px) */
@media (max-width: 640px) {
	.main-image {
	  height: 350px; /* Adjust the height for Small screens */
	}
  }
  
  /* Adjust main image height for Medium screens (641 - 1007px) */
  @media (min-width: 641px) and (max-width: 1007px) {
	.main-image {
	  height: 500px; /* Adjust the height for Medium screens */
	}
  }
  
  /* Adjust main image height for Large screens (1008px and up) */
  @media (min-width: 1008px) {
	.main-image {
	  height: 700px; /* Adjust the height for Large screens */
	}
  }
    
  .main-image img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit:cover;
	object-position: center;
	border-radius: 0px;
	transition: all 0.65s ease;
  }

.auction-gallery-timer {
    background-color: #1F2230;
    text-align: center;
    height: 40px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 5px;
	max-width: 95vw;
	width: 95vw;
	margin-left: auto;
	margin-right: auto;
	display: flex;
    align-items: center;
    justify-content: center;
}
  
/* Specific styles when status is 'Live' */
.auction-gallery-timer.live {
    display: grid;
    grid-template-columns: 1fr 1fr; 
}

.status-col {
    display: flex;
    align-items: center;
    justify-content: center;
}
  
  /* .live-status-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	align-items: center;
	justify-items: center;
  } */
  
  /* .status-col h5 {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
  } */
  

 .auction-gallery-timer h5 {
	font-size: 18px;
	color: #fff;
	margin: 0px;
	display: flex;
	align-items: center;
  }
  
  @media (max-width: 767px) {
	 .auction-gallery-timer h5 {
	  font-size: 18px;
	}
  }
  
  .auction-timer h5 {
	font-size: 16px;
	color: #fff;
	margin: 0px;
	display: flex;
	align-items: center;
  }

  @media (max-width: 767px) {
	.auction-timer h5 {
	 font-size: 16px;
   }
 }

  .main-image.zoomed {
	position: fixed;
	top: 0;
	left: 0;
	max-width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 1); /* Set background color to black */
	z-index: 1000;
	cursor: zoom-out;
  }
  
  .main-image.zoomed img {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
  }
  
  .zoom-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0); /* Adjust the alpha value to control darkness */
	z-index: 99;
	cursor: zoom-out;
  }

  #root {
	overflow: hidden;
  }

  .main-image.zoomed .image-number {
	position: absolute;
	top: 10px;
	right: 10px;
	color: white;
	font-size: 18px;
	font-weight: bold;
  }

  .arrow-container {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
  }

  .arrow-container.left {
	left: 20px;
  }
  
  .arrow-container.right {
	right: 20px;
  }
  
  .arrow-container:hover {
	background-color: #26a96c;
  }
  
  .arrow-overlay .arrow.left {
	margin-left: 20px;
  }

  .thumbnail-container {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 40%;
	overflow-x: auto ;
	overscroll-behavior-inline: contain;
	margin-top: 10px;
	margin-bottom: 10px;
	scroll-snap-type: inline mandatory;
	overflow-y: hidden;
  }
  
  .thumbnail img {
	cursor: pointer;
	box-shadow: 2px;
	inline-size: 100%;
	aspect-ratio: 16 / 9;
	object-fit: cover;
	object-position: center;
	border: 3px solid transparent;
	transition: border-color 0.2s ease-in-out;
	scroll-snap-align: start;
  }
  
  .thumbnail.selected img {
	border-color: #26a96c;
  }
  
  .delete-icon-container {
	top:-20px;
	right: -20px;
	font-size: 1.25rem;
	border-radius: 50%;
	background-color: #fff;
	display: flex;
	justify-content: center;
	border: 1px solid transparent;
	z-index: 1;
	cursor: pointer;
  }

  .blog-video-area .row {
	display: flex;
	justify-content: center;
  }
  
  .blog-video-area .col-md-6 {
	flex: 0 0 50%;
	display: flex;
  }
  
  .blog-video-area .image-container {
	position: relative;
	width: 100%;
	height: auto;
	padding-bottom: 75%;
  }
  
  .blog-video-area img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
  }

.auction-description body {
    font-family: "raleway", sans-serif;
}

.auction-description p {
    font-size: 0.9em;
    line-height: 1.5;
    color: #333;
}

.auction-description strong {
    font-weight: bold;
    color: #333;
}

.auction-description h1 {
    font-size: 1.4em;
    color: #333;
    margin-bottom: 0.5em;
	margin-top: 0.5em;
}

.auction-description h2 {
    font-size: 1.4em;
    color: #333;
    margin-bottom: 0.5em;
	margin-top: 0.5em;
}

.auction-description h3 {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 0.5em;
	margin-top: 0.5em;
}

.auction-description ul {
    padding-left: 1em;
    list-style-type: disc;
}

.auction-description li {
    font-size: 0.9em;
    color: #333;
    margin-bottom: 0.5em;
}

/* You can also add classes to specific elements if you want to style them differently */
.auction-description p.important {
    font-weight: bold;
    color: #d63a3a; /* For example, this would make the text red */
}

.message {
	padding: 1em;
	margin-top: 1em;
	width: fit-content;
	border-radius: 5px;
	width: 100%;
	text-align: center;
  }
  
  .message.success {
	background-color: #DFF0D8;
	color: #3C763D;
	border: 1px solid #D6E9C6;
  }
  
  .message.error {
	background-color: #F2DEDE;
	color: #A94442;
	border: 1px solid #EBCCD1;
  }
  

/* New CSS class for live auction container */
/* Add this to your CSS or styles file */
.skeleton {
	background-color: #f1f1f1;
	animation: pulse 1s infinite;
  }
  
  @keyframes pulse {
	0% {
	  opacity: 0.6;
	}
	50% {
	  opacity: 1;
	}
	100% {
	  opacity: 0.6;
	}
  }
  
  /* New CSS class for live auction container */
  .live-auction-container {
	display: grid;
	gap: 10px;
  }
  
  /* Media Queries for different breakpoints */
  @media (min-width: 1440px) {
	/* For screens with width >= 1200px, use 3 columns */
	.live-auction-container {
	  grid-template-columns: repeat(3, 1fr);
	}
  }
  
  @media (max-width: 1439px) and (min-width: 1200px) {
	/* For screens with width between 768px and 1199px, use 2 columns */
	.live-auction-container {
	  grid-template-columns: repeat(3, 1fr);
	}
  }

  @media (max-width: 1199px) and (min-width: 768px) {
	/* For screens with width between 768px and 1199px, use 2 columns */
	.live-auction-container {
	  grid-template-columns: repeat(2, 1fr);
	}
  }
  
  @media (max-width: 767px) {
	/* For screens with width < 768px, use 1 column */
	.live-auction-container {
	  grid-template-columns: repeat(1, 1fr);
	}
  }
  

/* Replace the existing styles with Bootstrap icon styles */
 .comment-reply {
	/* Adjust the padding to make space for the icon */
	padding: 10px;
	width: 25px;
	/* text-align: center; */
	transition: all 0.42s ease;
	font-size: 0.8rem;
	/* margin-top: 0px; */
	padding-left: 0px;

  }
  
  .comment-reply .bi {
	/* Adjust the icon size */
	font-size: 1.0rem;
	/* Add a slight margin to center the icon */
	padding: 3px;
	transition: all 0.42s ease;
  }
  
  /* Reverse the icon colors on hover */
  .comment-reply:hover .bi {
	color: #26a96c; /* Change the color back to the original color */
  }


  .input-with-btn {
	/* max-width: 350px; */
	width: 100%;
	border: 1px solid #696969;
	padding: 3px;
	border-radius: 5px;
  }
   .input-with-btn input {
	width: 100%;
	border-radius: 5px;
	background: transparent;
	border: none;
	outline: none;
	padding: 7px 10px;
	transition: all 0.4s ease;
	font-size: 14px;
	font-weight: 400;
  }

   .input-with-btn input::-moz-placeholder {
	font-size: 14px;
	font-weight: 400;
	color: #696969;
  }
   .input-with-btn input::-ms-input-placeholder {
	font-size: 14px;
	font-weight: 400;
	color: #696969;
  }
  .input-with-btn input::placeholder {
	font-size: 14px;
	font-weight: 400;
	color: #696969;
  }

  .input-with-btn textarea {
	width: 100%;
	border-radius: 5px;
	background: transparent;
	border: none;
	outline: none;
	padding: 7px 5px;
	transition: all 0.4s ease;
	font-size: 14px;
	font-weight: 400;
  }
   .input-with-btn textarea::-moz-placeholder {
	font-size: 14px;
	font-weight: 400;
	color: #696969;
  }
   .input-with-btn textarea::-ms-input-placeholder {
	font-size: 14px;
	font-weight: 400;
	color: #696969;
  }
  .input-with-btn textarea::placeholder{
	font-size: 14px;
	font-weight: 400;
	color: #696969;
  }

  .input-with-btn button {
	outline: none;
	border: none;
	background: #26a96c;
	border-radius: 5px;
	min-width: 30px;
	/*min-height: 40px;*/
	margin: 10px 10px;
	padding: 0px;
  }
 .input-with-btn button img {
	transition: 0.65s ease;
	padding: 5px;
  }
 .input-with-btn button:hover img {
	transform: rotate(360deg);
  }

.chip button {
	color: #26a96c;
	background-color: #fff;
	margin: 7px 5px;
	padding-top: 0px;
	font-size: 14px;
	border: 1px #26a96c;
	
}

/******************/

  .custom-image-gallery-slide-wrapper {
	display: flex;
	align-items: flex-start; /* Align main image to the left */
  }
  
  .custom-image-gallery-thumbnails-container {
	display: flex;
	overflow-x: auto;
	align-items: flex-start; /* Align thumbnails to the left */
	margin-top: 10px;
  }
  
  .custom-image-gallery-thumbnail {
	width: 100px;
	height: auto;
	flex-shrink: 0;
	margin-right: 5px;
}
  
  .custom-image-gallery-thumbnail img {
	width: 100%;
	height: 100%;
	object-fit: cover;
  }
  
  .custom-image-gallery-thumbnail.landscape img {
	width: 100%;
	height: auto;
	object-fit: contain;  
  }
  
  .hide-header {
	display: none;
  }
  
 /* Draggable styles */
.thumbnail.is-dragging {
	background-color: #f1f1f1;
  }
  
  .thumbnail.is-dragging img {
	opacity: 0.5;
	cursor: grabbing;
  }
  
  .thumbnail-dragging-over {
	border-color: #6c757d;
  }

  .additional-images-container {
	margin-top: 10px;
	margin-bottom: 50px;
  }

  .giveaway-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 260px;  /* fixed height */
    padding: 20px;
    background-color: #113939;
	text-align: center;
}

.giveaway-container h2 {
    color: #fff;
    margin-bottom: 20px;
}

.giveaway-container p {
	color: #fff;
	font-size: 14px;
}

.button-link {
	display: inline-block;
	padding: 8px 16px; /* Adjust padding as needed */
	background-color: #26a96c; /* Button background color */
	color: #fff; /* Button text color */
	text-decoration: none;
	border: none;
	border-radius: 5px;
	cursor: pointer;
  }
  
  .button-link:hover {
	background-color: #fff; /* Button background color on hover */
	color: #26a96c;
	border: 1px solid #26a96c;
  }
  
  /* This will align the button to the left on all screens */
.join-button {
	margin-left: 0;
	margin-right: auto;
	display: inline-block; /* or you can use 'display: block;' depending on your layout */
  }
  
  /* This will center the button only on screens smaller than 768px */
  @media (max-width: 767px) {
	 .join-button {
	  margin-left: auto;
	  margin-right: auto;
	  display: block;
	}
  }
  
  .bidder-content {
    height: auto;
}

.bid-chip {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #26a96c; /* Green background */
	color: #fff;
	font-size: small;
}

.mention {
    font-weight: bold;
    color: #26a96c; /* Same green for consistency */
}

/* CSS for filter sections */
.filter-section {
	width: 100%;
	display: flex;
	flex-direction: column;
	color: #26a96c;
	border: 1px solid #26a96c;
	margin-top: 10px;
	border-radius: 5px;
  }
  
  .filter-subsection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	border-bottom: 1px solid #26a96c;
  }
  
  .filter-subsection h3 {
	margin-left: 10px; 
	margin-top: 7px;
	font-size: 14px;
	color: #26a96c;
  }
  
  .filter-subsection button {
	background: none;
	border: none;
	font-size: 14px;
	cursor: pointer;
	width: 30px;
	position: absolute;
	right: 20px; 
	color: #26a96c;
  }
  
  .separator {
	width: 100%;
	height: 1px;
	background-color: #ccc; 
	margin: 10px 0;
  }
  
  .error-message {
	color: red;
	font-size: 1rem;
	margin-top: 5px;
	margin-bottom: 5px;
  }
  
  .input-error {
	border-color: red;
  }
  
  .blog-post-image {
	width: 100%; /* Make image fill the container */
	height: 300px; /* Set a fixed height or adjust as needed */
	object-fit: cover; /* Cover the area without distorting the aspect ratio */
	object-position: center; /* Center the image within the element */
  }
  
  .edit-thumbnail-container {
	display: flex;
	overflow-x: auto;
	gap: 10px; /* Adds space between thumbnails */
	padding: 10px 0;
	align-items: center; /* Align items in the middle vertically */
  }
  
  .edit-thumbnail {
	flex: 0 0 auto; /* Do not grow, do not shrink, and base width on content */
	width: 100px; /* Example fixed width, adjust based on your needs */
	height: 100px; /* Maintain aspect ratio if desired */
	position: relative; /* For positioning delete icon or other overlays */
  }
  
  .edit-thumbnail img {
	width: 100%;
	height: 100%;
	object-fit: cover; /* Adjust as per your preference */
  }
  
  .edit-file-input {
	width: 100%; /* Make the file input responsive */
	margin-bottom: 15px; /* Space between input and images */
  }
  



.filepond--drop-label {
	color: #4c4e53;
}

.filepond--label-action {
	text-decoration-color: #babdc0;
}

.filepond--panel-root {
	border-radius: 5px;
	background-color: #edf0f4;
	height: 1em;
}

.filepond--item-panel {
	background-color: #595e68;
}

.filepond--drip-blob {
	background-color: #7f8a9a;
}

/* Style for image items */
.filepond--item {
	border-radius: 5px; /* Rounded corners for the image item */
	overflow: hidden; /* Ensures the border radius clips the content */

  }
  
  /* Desktop view */
@media (min-width: 768px) { /* Adjust the breakpoint as needed */
	.filepond--root .filepond--list {
	  display: flex;
	  flex-wrap: wrap;
	  align-items: flex-start; /* Align items at the start of the container */
	}
	.filepond--item {
	  flex: 1 1 auto; /* Adjust the flex basis as per requirement */
	  margin-right: 10px; /* Space between items, adjust as needed */
	}
  }
  /* Style for the image preview */
  /* .filepond--image-preview {
	object-fit: cover; 
  } */
  
  /* Custom grid layout for FilePond items */
/* .filepond--list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
	grid-gap: 10px;
  }  */
  
  /* Adjustments for larger screens */
  /* @media (min-width: 768px) {
	.filepond--list {
	  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}
  }  */
  
  /* .filepond--item {
	width: 100%;
  } */
  
  /* @media (min-width: 768px) {
	.filepond--item {
	  width: 48%;
	}
	.filepond--list {
	  display: flex;
	  flex-wrap: wrap;
	  justify-content: space-between; 
	}
  } */
  
  
  
  /* Below 768px, let FilePond take the full width */
@media (max-width: 767px) {
	.filepond--root {
	  width: 100%;
	}
  }
  
  /* Above 768px, make the container horizontally scrollable */
  @media (min-width: 768px) {
	.horizontal-scroll-wrapper {
	  display: flex;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile devices */
	}
  
	.filepond--root {
	  flex: 0 0 auto; /* Do not grow, do not shrink, initial basis is auto */
	  width: auto; /* Adjust this based on your needs */
	  margin-right: 8px; /* Spacing between items */
	}
  }

  .offline-warning {
	z-index: 1001; /* Adjust accordingly based on your header's z-index */
	position: fixed; /* or 'absolute', depending on your layout */
	top: 0;
	width: 100%;
	background-color: red;
	color: white;
	text-align: center;
	padding: 10px;
  }
  